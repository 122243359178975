import isEmpty from 'just-is-empty';
import compare from 'just-compare';
import get from 'just-safe-get';
import clone from 'just-clone';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ModuleType } from '@/core/interfaces/permission.interface';

/**
 * Verifica si un objeto es vacío.
 *
 * @param obj - Objeto.
 */
export const isVarEmpty = (obj: any) => isEmpty(obj);

/**
 * Compara dos valores.
 *
 * @param value - Variable a comparar.
 * @param other - Otra variable a comparar.
 */
export const isSameVal = (value: any, other: any) => compare(value, other);

/**
 * Obtiene el valor de una propiedad de un objeto.
 *
 * @param obj - Objeto.
 * @param key - Llave.
 */
export const getObjValue = (obj: any, key: string) => get(obj, key);

/**
 * Clona un objeto.
 *
 * @param obj - Objeto.
 */
export const cloneDeep = (obj: any) => clone(obj);

/**
 * Obtiene un arreglo de colores aleatorios en hexadecimal.
 */
export const generateRandomColors = (num: number = 100) => {
  const colors = [];
  for (let i = 0; i < num; i++) {
    colors.push(getRandomColor());
  }
  return colors;
};

/**
 * Obtiene un color aleatorio en hexadecimal.
 */
export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 * Obtiene un dato de la metadata ("data") desde la ruta
 * actual hasta las rutas padres.
 *
 * @param routeSnapshot
 * @param key
 */
export const getRouteMetadataFromChild = (
  routeSnapshot: ActivatedRouteSnapshot,
  key: string
): null | ModuleType => {
  let metadata = routeSnapshot.data[key];
  if (metadata) return metadata;

  let parent = routeSnapshot.parent;
  while (parent) {
    metadata = parent.data[key];
    if (metadata) return metadata;
    parent = parent.firstChild;
  }

  return null;
};

/**
 * Transforma el string en formato oracion.
 *
 * @param sentence - string a convertir en oracion.
 */
export const toSentence = (sentence: string) => {
  // Verificar si la oración está vacía
  if (sentence.length === 0) return '';

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

/**
 * Reemplaza palabras en un texto.
 *
 * @param text - Cadena de texto.
 * @param replacements - Objeto con las palabras a reemplazar.
 */
export const replaceWords = (
  text: string,
  replacements: { [key: string]: string }
): string => {
  let result = text;

  for (const [key, value] of Object.entries(replacements)) {
    const regex = new RegExp(`\\b${key}\\b`, 'gi');

    result = result.replace(regex, value);
  }

  return result;
};
